import validator from '@rjsf/validator-ajv8';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { type JSONSchema7TypeName } from 'json-schema';

import authService from '../services/auth.service';
import { useAlert } from 'react-alert';
import Form, { type IChangeEvent } from '@rjsf/core';

export async function loginLoader(): Promise<any> {
  if (authService.isUserLoggedIn()) {
    return redirect('/');
  }
  return null;
}

export const LoginPage = (): React.ReactElement => {
  const alert = useAlert();

  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const from = params.get('from') ?? '/';

  const onSubmit = (e: IChangeEvent): void => {
    const { email, password } = e.formData;
    console.log('submit called', email, password);
    authService
      .login(email, password)
      .then(() => {
        console.log('redirecting', from);
        navigate(from);
      })
      .catch((e: Error) => {
        alert.error(`Login failed: ${e.toString()}`);
      });
  };

  const schema = {
    title: 'Log in',
    required: ['email', 'password'],
    properties: {
      email: {
        type: 'string' as JSONSchema7TypeName,
        title: 'Email',
      },
      password: {
        type: 'string' as JSONSchema7TypeName,
        title: 'Password',
      },
    },
  };
  return (
    <div className={'container'}>
      <div className={'row d-flex justify-content-center'}>
        {from.length > 1 && <p>You must log in to view the page at {from}</p>}
        <div className={'col-md-4 mt-5'}>
          <Form
            schema={schema}
            uiSchema={{
              password: { 'ui:widget': 'password' },
              email: { autofocus: true },
              'ui:submitButtonOptions': {
                props: {
                  disabled: false,
                  className: 'mt-3',
                },
              },
            }}
            validator={validator}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};
