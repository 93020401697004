import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import { TiTimes as TiClose } from 'react-icons/ti';
import { type Filter_t, operators } from '../../services/data.service';

export function FilterSelection(props: {
  filter_options: Array<{ title: string; ref: string }>;
  filters_applied: Filter_t[];
  filter_default: Filter_t;
}): React.ReactElement {
  function AppliedFilter(fProps: { f: Filter_t }): React.ReactElement {
    const location = useLocation();
    const navigate = useNavigate();

    const RemoveFilter = (): void => {
      console.log('remove filter', fProps.f);
      const filters = (props.filters_applied ?? []).filter(
        (f: Filter_t) => JSON.stringify(f) !== JSON.stringify(fProps.f),
      );
      navigate(location, {
        state: { ...location.state, filters },
      });
    };
    return (
      <Badge pill className={'m-1'} style={{ fontSize: '1em' }}>
        {fProps.f.ref}
        &nbsp;
        {operators[fProps.f.op]}
        &nbsp;
        <i>{fProps.f.val}</i>
        <TiClose size="1.4em" role="button" onClick={RemoveFilter} />
      </Badge>
    );
  }

  function AddFilter(aProps: {
    default: Filter_t;
    options: Array<{ title: string; ref: string }>;
  }): React.ReactElement {
    const location = useLocation();
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [nextFilter, setNextFilter] = useState<Filter_t>(aProps.default);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);

      if (form.checkValidity()) {
        navigate(location, {
          state: {
            ...location.state,
            filters: [...props.filters_applied, nextFilter],
          },
        });
        setValidated(false);
        setNextFilter(aProps.default);
      }
    };

    return (
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <Row className={'mb-2'}>
          <Col xs="auto">
            <Form.Select
              size="sm"
              aria-label="Select new filter column"
              value={nextFilter.ref}
              onChange={(e) => {
                setNextFilter({ ...nextFilter, ref: e.target.value });
              }}
            >
              {aProps.options.map((value, index) => (
                <option key={index} value={value.ref}>
                  {value.title}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs="auto">
            <Form.Select
              size="sm"
              aria-label="Select new filter column"
              value={nextFilter.op}
              onChange={(e) => {
                setNextFilter({
                  ...nextFilter,
                  op: e.target.value as keyof typeof operators,
                });
              }}
            >
              {Object.entries(operators).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs="auto">
            <Form.Control
              required
              size="sm"
              type="text"
              placeholder="value"
              value={nextFilter.val}
              onChange={(e) => {
                setNextFilter({ ...nextFilter, val: e.target.value });
              }}
            />
          </Col>
          <Col xs="auto">
            <Button type="submit">Add filter</Button>
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <div>
      <AddFilter
        default={props.filter_default}
        options={props.filter_options}
      />
      <div className={'mb-3'}>
        {props.filters_applied.map((f) => (
          <AppliedFilter key={JSON.stringify(f)} f={f} />
        ))}
      </div>
    </div>
  );
}
