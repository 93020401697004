import {DataAPI} from './data.service';

const API_URL = '/api/admin/';

class DeprecatedDataService {
  data_api = new DataAPI(API_URL);
  async getApiSpec(): Promise<Record<any, any>> {
    return await this.data_api.get('docs/apispec.json');
  }

  async getUserApiSpec(): Promise<Record<any, any>> {
    return await this.data_api.get('../user/openapi.json');
  }

  async getInfoDocs(): Promise<string> {
    return await this.data_api.get('docs/index.html');
  }

  async getBanner(): Promise<{ banner: string }> {
    return await this.data_api.get('server/info/banner');
  }
}

export default new DeprecatedDataService();
