import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table as BootstrapTable } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import DataService from '../../services/data.service';
import { PageSelect } from './page_select.component';
import { TableHead } from './table_head.component';
import { TableBody } from './table_body.component';
import { TableTitle } from './table_title.component';
import { FilterSelection } from './filter_selection.component';
import { useGetData } from '../../hooks/useGetData';

function TablePage(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const { categoryName, itemName } = useParams();

  const [data, isLoading, failed] = useGetData(
    async () =>
      await DataService.getTable(
        { categoryName: categoryName ?? '', itemName: itemName ?? '' },
        location.state?.page,
        location.state?.per_page,
        location.state?.sort,
        location.state?.filters,
      ),
    [categoryName, itemName, location],
  );

  const setPage = (page: number): void => {
    navigate(location, {
      state: { ...location.state, page },
    });
  };

  if (isLoading) {
    // TODO animated spinner
    return <div>Loading data...</div>;
  }

  if (
    failed !== '' ||
    data === undefined ||
    categoryName === undefined ||
    itemName === undefined
  ) {
    return <div>Failed loading data: {failed}</div>;
  }

  return (
    <Container className={'mt-3'}>
      <TableTitle
        title={data.title}
        createName={data.create_name}
        categoryName={categoryName}
        itemName={itemName}
        description={data.description}
      />

      <FilterSelection
        filter_default={data.filter_default}
        filters_applied={data.filters_applied}
        filter_options={data.filter_options}
      />

      <BootstrapTable striped bordered hover size="sm">
        <TableHead data={data.header} />
        <TableBody data={data.data} />
      </BootstrapTable>

      <PageSelect
        page={data.page}
        perPage={data.per_page}
        totalPages={data.total_pages}
        setPage={setPage}
      />
    </Container>
  );
}

export default TablePage;
