import React from 'react';
import {Link, NavLink} from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '../assets/favicon.png';

import dataService from '../services/data.service';
import {useGetData} from '../hooks/useGetData';

function navigation(): React.ReactElement {
  const [data, isLoading] = useGetData(
    async () => await dataService.getNavigation(),
    [],
  );

  if (isLoading) {
    return (
      <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
        <Container>Loading...</Container>
      </Navbar>
    );
  }

  console.log('navigation data: ', data);

  return (
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top me-2"
          />
          {data?.title ?? ''}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {(data?.categories ?? []).map((category: any) => (
              <NavDropdown
                title={category?.name}
                key={category?.name}
                id="collapsible-nav-dropdown"
              >
                {(category?.items ?? []).map((itemName: string) => (
                  <NavDropdown.Item
                    key={`${category?.name}-${itemName}`}
                    as={NavLink}
                    to={`/g/${category?.name}/${itemName}/table`}
                  >
                    {itemName}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            ))}

            <NavDropdown title={'Other'} id="collapsible-nav-dropdown">
              <NavDropdown.Item as={NavLink} to={`/docs/info`}>
                Docs
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/docs/api`}>
                Api Docs
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/docs/api/user`}>
                User Api Docs
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/other/flower`}>
                Flower
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/other/mailcatcher`}>
                Mailcatcher
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link as={NavLink} to={'/logout'}>
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default navigation;
