import DataService, {
  type CellData_t,
  type Link_t,
} from '../services/data.service';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useAlert } from 'react-alert';

function CellLink(props: React.PropsWithChildren<Link_t>): React.ReactElement {
  if (props.view === 'entity') {
    return (
      <Link to={`/g/${props.category}/${props.name}/${props.view}/${props.id}`}>
        {props.children}
      </Link>
    );
  } else if (props.view === 'table') {
    return (
      <Link
        to={`/g/${props.category}/${props.name}/${props.view}`}
        state={{ filters: props.filters }}
      >
        {props.children}
      </Link>
    );
  } else {
    throw new Error(`Invalid link: ${JSON.stringify(props)}`);
  }
}

function CellButton(
  props: Extract<CellData_t, { type: 'action' }>,
): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();

  const action = (): void => {
    DataService.callAction(
      {
        categoryName: props.category_name,
        itemName: props.item_name,
      },
      props.action_ref,
      props.item_id,
    )
      .then((data) => {
        alert.success(data.message);
        navigate(location, {
          state: {
            ...location.state,
            reload: (location.state?.reload ?? 1) + 1,
          },
          replace: true,
          preventScrollReset: true,
        });
      })
      .catch((e) => {
        alert.error(`${e}`);
      });
  };
  return <Button onClick={action}>{(props.value ?? '').toString()}</Button>;
}

function RenderedCell(props: { data: CellData_t }): React.ReactElement {
  if (props.data.type === 'string')
    return <>{(props.data.value ?? '').toString()}</>;
  if (props.data.type === 'link')
    return (
      <>
        <CellLink {...props.data.link}>
          {(props.data.value ?? '').toString()}
        </CellLink>
      </>
    );
  if (props.data.type === 'action') {
    return <CellButton {...props.data} />;
  }
  return <>{JSON.stringify(props.data)}</>;
}

export default RenderedCell;
