import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import DataService from '../../services/data.service';
import { useState } from 'react';

export function ExportButton(props: {
  categoryName: string;
  itemName: string;
}): React.ReactElement {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  function onClick(): void {
    console.log('export button clicked');
    setLoading(true);
    void DataService.getExportTable(
      { categoryName: props.categoryName, itemName: props.itemName },
      location.state?.sort,
      location.state?.filters,
    ).then((data) => {
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      // create a filename based on the current time and the table name
      const date = Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
        .format(new Date())
        .replace(/\//g, '-');

      const filename = `${props.itemName}_${date}.csv`;

      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });
  }

  if (loading) return <Button disabled>Exporting...</Button>;

  return (
    <Button
      onClick={onClick}
      title={'Export whole table based on current filters'}
    >
      Export
    </Button>
  );
}

export default ExportButton;
