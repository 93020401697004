import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

export function useGetData<DataReturn>(
  getDataFunction: () => Promise<DataReturn>,
  deps: any[] = [],
  reportSuccess: boolean = false,
): [DataReturn | undefined, boolean, string] {
  const alert = useAlert();
  const [data, setData] = useState<DataReturn>();
  const [isLoading, setIsLoading] = useState(false);
  const [failed, setFailed] = useState('');

  useEffect(() => {
    let ignore = false;

    setIsLoading(true);

    getDataFunction()
      .then((d: DataReturn) => {
        if (ignore) return;

        setData(d);
        setFailed('');
        if (reportSuccess) alert.success('SUCCESS'); // TODO extract message from data
      })
      .catch((reason) => {
        if (ignore) return;

        console.log('data loading failed...', reason, typeof reason);
        setFailed(`Loading data failed ${reason}`);
        alert.error(`Failed: ${reason}`);
      })
      .finally(() => {
        if (ignore) return;

        setIsLoading(false);
      });
    return () => {
      ignore = true;
    };
  }, [...deps]);
  return [data, isLoading, failed];
}
