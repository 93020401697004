import { type JSONSchema7 } from 'json-schema';
import axios, { type AxiosError, type AxiosResponse } from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service';

const API_URL = '/api/admin/auto/';

export const operators = {
  eq: '=',
  ne: '!=',
  lt: '<',
  le: '<=',
  gt: '>',
  ge: '>=',
  in: 'in',
  like: 'like',
};

export interface Filter_t {
  ref: string;
  op: keyof typeof operators;
  val: string;
}

export type Link_t =
  | { category: string; name: string; view: 'entity'; id: string }
  | {
      category: string;
      name: string;
      view: 'table';
      filters: Filter_t[];
    };

export type CellData_t =
  | { type: 'string'; value: string }
  | {
      type: 'link';
      value: string;
      link: Link_t;
    }
  | {
      type: 'action';
      value: string;
      action_ref: string;
      category_name: string;
      item_id: string;
      item_name: string;
    };

export interface TableData_t {
  title: string;
  create_name: string;
  description: string;

  filter_options: Array<{ title: string; ref: string }>;
  filters_applied: Filter_t[];
  filter_default: Filter_t;

  header: Array<{ title: string; ref: string; dir: 'asc' | 'desc' | null }>;
  data: CellData_t[][];

  page: number;
  per_page: number;
  total_items: number;
  total_pages: number;
}

export interface CreateDefinition_t {
  title: string;
  description: string;

  order: string[];
  properties: Record<string, JSONSchema7>;
  required: string[];

  type: 'object';
}

export interface ItemAction_t {
  title: string;
  ref: string;
  description: string;
  item_name: string;
  category_name: string;
  item_id: string;
  parameters: Array<{
    ref: string;
    title: string;
    type: string;
  }>;
}
interface Item_t {
  title: string;
  description?: string;
  data: Array<{
    title: string;
    value: CellData_t;
  }>;
  actions: ItemAction_t[];
  tables: Array<Extract<Link_t, { view: 'table' }>>;
}

export class DataAPI {
  apiUrl: string;
  // constructor which takes API_URL
  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async get<T>(url: string): Promise<T> {
    return await this._process<T>(
      axios.get<T>(this.apiUrl + url, { headers: authHeader() }),
    );
  }

  async post<T>(url: string, data: any = {}): Promise<T> {
    return await this._process<T>(
      axios.post<T>(this.apiUrl + url, data, { headers: authHeader() }),
    );
  }

  async _process<T>(request: Promise<AxiosResponse<T>>): Promise<T> {
    return await request
      .then((result) => result.data)
      .catch((e: AxiosError<{ message: string }>) => {
        console.log('axios error: ', e);
        console.log('status code: ', e?.response?.status);

        if (e?.response?.status === 401) {
          AuthService.logout();
        }

        throw new Error(e?.response?.data?.message ?? 'Request failed');
      });
  }
}

interface Location_t {
  categoryName: string;
  itemName: string;
}

class DataService {
  data_api = new DataAPI(API_URL);

  async getNavigation(): Promise<any> {
    return await this.data_api.get('navigation');
  }

  async getTable(
    o: Location_t,
    page: number | null = null,
    perPage: number | null = null,
    sort: { ref: string; dir: string } | null = null,
    filters: any = null,
  ): Promise<TableData_t> {
    return await this.data_api.post(`${o.categoryName}/${o.itemName}/table`, {
      page,
      per_page: perPage,
      sort,
      filters,
    });
  }

  async getCreateDefinition(o: Location_t): Promise<CreateDefinition_t> {
    return await this.data_api.get(`${o.categoryName}/${o.itemName}/create`);
  }

  async createItem(
    o: Location_t,
    data: any,
  ): Promise<{
    link: any;
    message: string;
  }> {
    return await this.data_api.post(
      `${o.categoryName}/${o.itemName}/create`,
      data,
    );
  }

  async callAction(
    o: Location_t,
    actionRef: string,
    itemId: string,
  ): Promise<{
    link: any;
    message: string;
  }> {
    return await this.data_api.post(
      `${o.categoryName}/${o.itemName}/table/${itemId}/action/${actionRef}`,
      {},
    );
  }

  async getEntity(o: Location_t, itemId: string): Promise<Item_t> {
    return await this.data_api.get(
      `${o.categoryName}/${o.itemName}/item/${itemId}`,
    );
  }

  async callItemAction(
    o: Location_t,
    itemId: string,
    actionRef: string,
    data: any,
  ): Promise<any> {
    return await this.data_api.post(
      `${o.categoryName}/${o.itemName}/item/${itemId}/action/${actionRef}`,
      data,
    );
  }

  async getExportTable(
    o: Location_t,
    sort: { ref: string; dir: string } | null = null,
    filters: any = null,
  ): Promise<string> {
    return await this.data_api.post<string>(
      `${o.categoryName}/${o.itemName}/export`,
      {
        sort,
        filters,
      },
    );
  }
}

export default new DataService();
