import React from 'react';
import { RouterProvider } from 'react-router-dom';
import {
  type AlertOptions,
  type AlertTemplateProps,
  Provider as AlertProvider,
} from 'react-alert';
import { Alert } from 'react-bootstrap';

import './App.css';
import { router } from './AppRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';

const options: AlertOptions = {
  position: 'bottom right',
  timeout: 5000,
  offset: '30px',
  transition: 'scale',
};

function AlertTemplate({
  style,
  options,
  message,
  close,
}: AlertTemplateProps): React.ReactElement {
  console.log(
    'AlertTemplate',
    Alert,
    style,
    options,
    options.type,
    message,
    close,
  );
  const variantMap = {
    error: 'danger',
    success: 'success',
    info: 'info',
    warning: 'warning',
  };
  const variant = variantMap?.[options.type ?? 'error'] ?? 'danger';
  return (
    <Alert style={style} variant={variant} onClose={close} dismissible>
      {message}
    </Alert>
  );
}

export default function App(): React.ReactElement {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <RouterProvider
        router={router}
        fallbackElement={<p>Initial Load...</p>}
      />
    </AlertProvider>
  );
}
