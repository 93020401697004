import React from 'react';
import { getToken } from '../../services/auth-header';

const FlowerPage = (): React.ReactElement => {
  // generate url for flower which is at /flower

  const token = getToken();
  const baseUrl = '/flower';

  // Construct the URL with basic auth credentials
  const port = window.location.port !== '' ? `:${window.location.port}` : '';
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const urlWithAuth = `${protocol}//${'None'}:${token}@${hostname}${port}${baseUrl}`;

  return (
    <div>
      <iframe src={urlWithAuth} width="100%" height="1000px" />
    </div>
  );
};

export default FlowerPage;
