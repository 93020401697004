import { RedocStandalone } from 'redoc';
import React from 'react';

const RedocWrap = ({ spec }: { spec: Record<any, any> }): React.ReactElement => {

  const outerContainerStyle: React.CSSProperties = {
    position: 'absolute',
    inset: 0,
    scrollPadding: '0 !important',
    // @ts-expect-error incorrect type on scrollBehavior
    scrollBehavior: 'unset !important',
    overflowY: 'scroll',
  };
    return (<div style={{position: 'relative',
  height: 'calc(100dvh - 56px)'}}>
        <div style={outerContainerStyle}>
          <RedocStandalone spec={spec} /></div>
      </div>
    );
};

export default RedocWrap;
