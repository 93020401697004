import React, { memo } from 'react';
import { useGetData } from '../../hooks/useGetData';
import DeprecatedDataService from '../../services/deprecated-data.service';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Line } from 'react-chartjs-2';
import { Row } from 'react-bootstrap';
import { type Element } from 'hast';

const HomePage = memo(function HomePage(): React.ReactElement {
  const [data, isLoading, failed] = useGetData(
    async () => await DeprecatedDataService.getBanner(),
  );

  if (isLoading) {
    // TODO animated spinner
    return <div>Loading data...</div>;
  }

  if (failed !== '' || data === undefined) {
    return <div>Failed loading data: {failed}</div>;
  }

  const code = ({
    node,
    className,
    children,
    ...props
  }: {
    node?: Element;
    className?: string;
    children?: React.ReactNode;
  }): React.ReactElement => {
    if (className === 'language-chartjs') {
      const data = JSON.parse(children?.toString() ?? '');
      return (
        <div className="w-100">
          <h3>{data?.title ?? ''}</h3>
          <Line options={data.options} data={data.data} redraw={true} />
        </div>
      );
    }
    if (className === 'language-leaflet') {
      const data = JSON.parse(children?.toString() ?? '');
      console.log('leaflet data', data);
      return (
        <div className="w-100">
          <h3>{data?.title ?? ''}</h3>
          <pre>
            <big>
              <strong>TODO: </strong>
            </big>
            {JSON.stringify(data)}
          </pre>
        </div>
      );
    }

    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  };

  const pre = ({
    node,
    children,
    ...props
  }: {
    node?: Element;
    className?: string;
    children?: React.ReactNode;
  }): React.ReactElement => {
    if (((children as any)?.props?.className ?? '') === 'language-chartjs') {
      return <div className="col col-md-6 mx-auto">{children}</div>;
    }
    if (((children as any)?.props?.className ?? '') === 'language-leaflet') {
      return <div className="col col-md-6 mx-auto">{children}</div>;
    }
    return <pre {...props}>{children}</pre>;
  };

  return (
    <Row>
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ node, ...rest }) => (
            <p style={{ marginBottom: 'initial' }} {...rest} />
          ),
          pre,
          code,
        }}
      >
        {data.banner}
      </Markdown>
    </Row>
  );
});
export default HomePage;
