import validator from '@rjsf/validator-ajv8';
import { type IChangeEvent, type ThemeProps, withTheme } from '@rjsf/core';
import { Theme } from '@rjsf/bootstrap-4';
import { getSubmitButtonOptions, type SubmitButtonProps } from '@rjsf/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import DataService, { type Link_t } from '../../services/data.service';
import { Container } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import Markdown from 'react-markdown';
import { useGetData } from '../../hooks/useGetData';

/*
 * Workaround for buggy file upload
 * https://github.com/rjsf-team/react-jsonschema-form/issues/2095#issuecomment-844309622
const registry = getDefaultRegistry();
const defaultFileWidget = registry.widgets['FileWidget'];
(Theme as any).widgets['FileWidget'] = defaultFileWidget;
const Form = withTheme(Theme);
*/

function SubmitButton(props: SubmitButtonProps): React.ReactElement {
  /* custom Submit button so that we can add "Cancel" with redirect back to table */
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryName, itemName } = useParams();

  console.log('SubmitButton', props);

  const { props: uiProps } = getSubmitButtonOptions(props.uiSchema);
  if (uiProps?.loading === true) {
    return <div>Loading...</div>;
  }

  if (categoryName === undefined || itemName === undefined) {
    // this should never happen
    return <div>Loading???</div>;
  }

  return (
    <div className="mt-3">
      <Button className="me-3" type="submit">
        Submit
      </Button>
      <Button
        variant={'danger'}
        onClick={() => {
          if (location.key === 'default')
            navigate(`/auto/${categoryName}/${itemName}/table`);
          else navigate(-1);
        }}
      >
        Cancel
      </Button>
    </div>
  );
}

const ThemeObject: ThemeProps = {
  templates: {
    ButtonTemplates: {
      SubmitButton,
    },
  },
  ...Theme,
};

const Form = withTheme(ThemeObject);

export function CreatePage(): React.ReactElement {
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const { categoryName, itemName } = useParams();

  const [data, isLoading, failed] = useGetData(
    async () =>
      await DataService.getCreateDefinition({
        categoryName: categoryName ?? '',
        itemName: itemName ?? '',
      }),
    [categoryName, itemName, location],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<object>();

  if (categoryName === undefined || itemName === undefined) {
    // this should never happen
    return <div>Loading...</div>;
  }

  if (isLoading) {
    // TODO animated spinner
    return <div>Loading data...</div>;
  }

  if (failed !== '' || data === undefined) {
    return <div>Failed loading data: {failed}</div>;
  }

  const uiSchema = {
    'ui:order': [...data.order, '*'],
    'ui:submitButtonOptions': {
      props: {
        loading: isSubmitting,
      },
    },
  };

  const onSubmit = ({ formData }: IChangeEvent): void => {
    console.log('submit event', formData);
    setIsSubmitting(true);
    DataService.createItem({ categoryName, itemName }, formData)
      .then((value) => {
        alert.success(value?.message ?? 'Success');

        const link: Link_t = value?.link;
        if (link === undefined) return;

        if (link.view === 'entity') {
          navigate(`/g/${link.category}/${link.name}/${link.view}/${link.id}`);
        }
        if (link.view === 'table') {
          navigate(`/g/${link.category}/${link.name}/${link.view}`, {
            state: { filters: link.filters ?? [] },
          });
        }
      })
      .catch((reason) => {
        console.log('Failed create', reason);
        alert.error(`Failed: ${reason}`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Container className={'mt-3'}>
      <h1 className={'mb-3'}>{data.title}</h1>
      <Markdown>{data.description}</Markdown>

      <Form
        schema={{ ...data, title: undefined, description: undefined }}
        uiSchema={uiSchema}
        formData={formData}
        onChange={(formState) => {
          setFormData(formState.formData);
        }}
        validator={validator}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

export default CreatePage;
