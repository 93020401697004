import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti';

export function TableHead(props: {
  data: Array<{ title: string; ref: string; dir: 'asc' | 'desc' | null }>;
}): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const setSort = (ref: string, dir: 'asc' | 'desc' | null) => (): void => {
    console.log('set_sort', ref, dir);
    navigate(location, {
      state: { ...location.state, sort: { ref, dir } },
    });
  };

  function DirectionArrow(props: {
    c_ref: string;
    dir: 'asc' | 'desc' | null;
  }): React.ReactElement {
    const ref = props.c_ref;
    if (props.dir === 'asc')
      return <TiArrowSortedUp role="button" onClick={setSort(ref, 'desc')} />;
    else if (props.dir === 'desc')
      return <TiArrowSortedDown role="button" onClick={setSort(ref, 'asc')} />;
    else return <TiArrowUnsorted role="button" onClick={setSort(ref, 'asc')} />;
  }

  return (
    <thead>
      <tr>
        {props.data.map((c: any, i) => (
          <th key={i}>
            {c.title}
            <DirectionArrow c_ref={c.ref} dir={c.dir} />
          </th>
        ))}
      </tr>
    </thead>
  );
}
