import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Markdown from 'react-markdown';
import ExportButton from './export_button';

export function TableTitle(props: {
  title: string;
  createName?: string;
  categoryName: string;
  itemName: string;
  description?: string;
}): React.ReactElement {
  const navigate = useNavigate();

  return (
    <>
      <h1 className={'mb-3'}>
        <span className={'me-3'}>{props.title}</span>
        {(props.createName ?? '') !== '' ? (
          <span className={'me-3'}>
            {' '}
            <Button
              onClick={() => {
                navigate(`/g/${props.categoryName}/${props.itemName}/create`);
              }}
            >
              {props.createName}
            </Button>
          </span>
        ) : null}
        <ExportButton
          categoryName={props.categoryName}
          itemName={props.itemName}
        />
      </h1>
      {(props.description ?? '') !== '' ? (
        <Markdown>{props.description}</Markdown>
      ) : null}
    </>
  );
}
