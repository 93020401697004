import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export function PageSelect(props: {
  page: number;
  perPage: number;
  totalPages: number;
  setPage: (page: number) => void;
}): React.ReactElement {
  const setPage = (page: number) => () => {
    props.setPage(page);
  };
  return (
    <Pagination className={'d-flex justify-content-center'}>
      {props.page >= 4 ? (
        <>
          <Pagination.First onClick={setPage(1)} />
          <Pagination.Item onClick={setPage(1)}>{1}</Pagination.Item>
          <Pagination.Ellipsis disabled />
        </>
      ) : (
        <Pagination.First disabled />
      )}

      {props.page >= 3 ? (
        <Pagination.Item onClick={setPage(props.page - 2)}>
          {props.page - 2}
        </Pagination.Item>
      ) : null}
      {props.page >= 2 ? (
        <Pagination.Item onClick={setPage(props.page - 1)}>
          {props.page - 1}
        </Pagination.Item>
      ) : null}

      <Pagination.Item active>{props.page}</Pagination.Item>

      {props.totalPages - props.page >= 2 ? (
        <Pagination.Item onClick={setPage(props.page + 1)}>
          {props.page + 1}
        </Pagination.Item>
      ) : null}
      {props.totalPages - props.page >= 3 ? (
        <Pagination.Item onClick={setPage(props.page + 2)}>
          {props.page + 2}
        </Pagination.Item>
      ) : null}

      {props.totalPages - props.page >= 4 ? (
        <>
          <Pagination.Ellipsis disabled />
          <Pagination.Item onClick={setPage(props.totalPages)}>
            {props.totalPages}
          </Pagination.Item>
          <Pagination.Last onClick={setPage(props.totalPages)} />
        </>
      ) : (
        <Pagination.Last disabled />
      )}
    </Pagination>
  );
}
