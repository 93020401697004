export function getToken(): string {
  const userStr = localStorage.getItem('user');
  let user = null;
  if (userStr != null) user = JSON.parse(userStr);

  if (user?.token !== '') {
    return user?.token;
  } else {
    return '';
  }
}

export default function authHeader(): { Authorization?: string } {
  const token = getToken();
  return token !== '' ? { Authorization: 'Bearer ' + token } : {};
}
