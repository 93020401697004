import axios, { type AxiosResponse } from 'axios';
import { redirect } from 'react-router-dom';

const API_URL = '/api/admin/auto/';

class AuthService {
  async login(username: string, password: string): Promise<any> {
    return await axios
      .post(API_URL + 'login', {
        username,
        password,
      })
      .then((response: AxiosResponse<{ token: string }>) => {
        if (response.data.token !== '') {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout(): void {
    localStorage.removeItem('user');
    redirect('/login');
  }

  isUserLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    return user != null;
  }
}

export default new AuthService();
