import { type CellData_t } from '../../services/data.service';
import React from 'react';
import RenderedCell from '../../components/rendered_cell.component';

export function TableBody(props: { data: CellData_t[][] }): React.ReactElement {
  return (
    <tbody>
      {props.data.map((row, idx) => (
        <tr key={idx}>
          {row.map((cell, idx) => (
            <td key={idx}>
              <RenderedCell data={cell} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}
