import React from 'react';
import { useGetData } from '../../hooks/useGetData';
import DeprecatedDataService from '../../services/deprecated-data.service';

const InfoPage = (): React.ReactElement => {
  const [data, isLoading, failed] = useGetData(
    async () => await DeprecatedDataService.getInfoDocs(),
  );

  if (isLoading) {
    // TODO animated spinner
    return <div>Loading data...</div>;
  }

  if (failed !== '' || data === undefined) {
    return <div>Failed loading data: {failed}</div>;
  }

  return (
    <iframe
      style={{ height: 'calc(100vh - 100px)' }}
      title={'API documentation'}
      className="mt-3 mh-100 w-100 shadow rounded"
      srcDoc={data}
    ></iframe>
  );
};

export default InfoPage;
